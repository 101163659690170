<template>
  <section class="news-preview bg-green-light py-16">
    <div class="constrain">
      <div class="text-center mb-8s">
        <Go
          :to="$store.state.router.page.meta.translatedPaths.de !== payload.link ? payload.link : ''"
          class="no-underline"
        >
          <h2 v-text="payload.title" />
        </Go>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div
          v-for="(entry, i) in payload.news"
          :key="entry.id"
          v-view
          :class="{
            'in-left': i === 0,
            'in-bottom': i === 1,
            'in-right': i === 2,
          }"
          class="animate"
        >
          <small v-text="entry.date" />
          <h3 v-text="entry.title" />
          <div v-html="entry.text" />
          <div v-if="entry.files.length" class="mt-4">
            <a
              v-for="download in entry.files"
              :key="entry.id + '-' + download.id"
              :href="download.link"
              class="btn hover-primary"
              target="_blank"
              download
              v-text="download.buttontext"
            />
          </div>
          <Go
            v-if="entry.link"
            :to="entry.link"
            class="underline hover:text-decoration-none inline-block mt-4 text-sm"
          >
            Mehr erfahren
          </Go>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>
